import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, getPageLang, getLangIndex } from '../lib/helpers'
import BlockContent from '../components/block-content'

import Container from '../components/container'
import Layout from '../containers/layout'

export const query = graphql`
  query UserAgreementQuery {
    userAgreement: allSanityUserAgreement(limit: 6) {
      edges {
        node {
          title
          pageTitle
          _rawPageText
        }
      }
    }
  }
`
const UserAgreementPage = ({ data, pageContext }) => {
  const pages = (data || {}).userAgreement
    ? mapEdgesToNodes(data.userAgreement)
    : []

  const lang = getPageLang(pageContext)
  const currentLang = getLangIndex(pages, lang)

  return (
    <Layout pageContext={pageContext} title={pages[currentLang].pageTitle}>
      <Container>
        <h1>{pages[currentLang].pageTitle || 'User agreement'}</h1>
        <BlockContent blocks={pages[currentLang]._rawPageText || []} />
      </Container>
    </Layout>
  )
}
export default UserAgreementPage
